<template>
  <div class="auth-page-container">
    <loader v-if="loading"/>
  </div>
</template>

<script>
  import Loader from '@/components/loader/loader';
  import Auth from '@/service/authService';
  import { amsClient } from '@/service/ams';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import Config from '@/service/config';
  import PopupService from '@/service/popup';

  export default {
    name: 'CallbacksContainer',
    components: {
      Loader,
    },
    data() {
      return {
        loading: true,
        type: null,
        status: 'fungus',
      };
    },
    beforeRouteEnter(_to, _from, next) {
      next((vm) => {
        console.log("TO PARAMS =>", _to);
        // console.log(_to.params.type, _to.params.status, _to.query, _to.query.pk, _to.query.code);
        if (_to.params.type === 'register' && _to.params.status === 'confirm') {
          if (Auth.isAuth() && !Auth.isGuest()) {
            vm.$router.push({
              path: '/',
            });
            return;
          }
          let mess = vm.$i18n.t('REGISTER_FORM.SUCCESSFULLY_REGISTER_CONFIRM');
          Auth.confirmRegister(_to.query.pk, _to.query.code)
            .then((response) => {
              setTimeout(() => {
                vm.$bus.$emit('toggleAuthPopup', {
                  component: 'login',
                  force: true,
                  outsideClose: false,
                  message: mess,
                  formId: 'fromPlayer',
                });
              }, 1000);
            })
            .catch((error) => {
              setTimeout(() => {
                vm.$bus.$emit('toggleAuthPopup', {
                  component: 'login',
                  force: true,
                  outsideClose: false,
                  message: error.message,
                  formId: 'fromPlayer',
                });
              }, 1000);
            })
            .finally(() => {
              let path = '/';
              if (_to.query.redirect_to) {
                try {
                  let url = new URL(_to.query.redirect_to);
                  path = url.href
                    .replace(url.origin, '')
                    .replace('#/', '')
                    .replace('portal/', '');
                } catch (e) {
                  console.warn('error during parse "redirect_to"', e);
                }
              }
              localStore.set('afterRegister', true);
              vm.$router.push({
                path: path,
              });
            });
        } else if (
          _to.params.type === 'account' &&
          _to.params.status === 'reset_password'
        ) {
          amsClient.account
            .verifyToken(_to.query.pk, _to.query.token)
            .then((res) => {
              if (res['token_status'] === true) {
                setTimeout(() => {
                  vm.$bus.$emit('toggleAuthPopup', {
                    component: 'forgot',
                    force: true,
                    outsideClose: false,
                    messObj: { },
                    accessData: {
                      pk: _to.query.pk,
                      token: _to.query.token,
                    },
                  });
                }, 1000);
              }
            })
            .catch((error) => {
              const errorMessage = vm.$i18n.t('FORGOT_PASS.NOT_CORRECT_TOKEN');
              setTimeout(() => {
                vm.$bus.$emit('toggleAuthPopup', {
                  component: 'forgot',
                  force: true,
                  forceCloseBtn: true,
                  messObj: {
                    text: errorMessage,
                    status: 'error',
                    type: 'secondary',
                  },
                });
              }, 1000);
            })
            .finally(() => {
              let path = '/';
              if (_to.query.redirect_to) {
                try {
                  let url = new URL(_to.query.redirect_to);
                  path = url.href
                    .replace(url.origin, '')
                    .replace('#/', '')
                    .replace('portal/', '');
                } catch (e) {
                  console.warn('error during parse "redirect_to"', e);
                }
              }
              localStore.set('afterRegister', true);
              vm.$router.push({
                path: path,
              });
            });
        } else if (_to.params.type === 'google' && _to.params.status === 'any') {
          Config.loaded(() => {
            Auth.loginByGoogle(_to.query.code)
              .then(() => {
                // PopupService.setAccountPopupState(true);
                // PopupService.skipAccountPopup(!!!+localStore.get('show-account-after-sso-login'));
                // localStore.delete('show-account-after-sso-login');

                if (Auth.getPortalPrevPath()) {
                  vm.$router.push({ path: Auth.getPortalPrevPath() });

                  localStore.delete('auth-redirect-url');
                } else {
                  vm.$router.push({ path: '/' });
                }
              });
          });
        } else if (_to.params.type === 'facebook' && _to.params.status === 'any') {
          Config.loaded(() => {
            Auth.loginByFacebook(_to.query.code)
              .then((res) => {
                // PopupService.setAccountPopupState(true);
                // PopupService.skipAccountPopup(!!!+localStore.get('show-account-after-sso-login'));
                // localStore.delete('show-account-after-sso-login');

                if (Auth.getPortalPrevPath()) {
                  vm.$router.push({ path: Auth.getPortalPrevPath() });

                  localStore.delete('auth-redirect-url');
                } else {
                  vm.$router.push({ path: '/' });
                }
              }).catch(() => {
                const CONFIRM_CONFIG = {
                  centered: true,
                  size: 'confirm',
                  okTitle: 'Ok',
                };

                vm
                  .$bvModal
                  .msgBoxOk('Authorization failed, please allow access to your email address', CONFIRM_CONFIG)
                  .then((confirm) => {
                    if (confirm) {
                      vm.$router.push('/')
                    }
                  });
              });
          });
        }
      });
    },
    beforeRouteUpdate(_to, _from, next) {
      next();
    },
  };
</script>
